import React, {useState} from 'react';
import {Button, Grid, Stack, Typography} from "@mui/material";

import copy from 'copy-to-clipboard';

import Image from "../../../components/Image/Image";
import MoleculeEditor from "../../../components/MoleculeEditor/MoleculeEditor";
import ChemicalStructureRecognitionAPI from "../../../api/ChemicalStructureRecognitionAPI";
import {popErrorMsg} from "../../../redux/errorMsg/errMsgSlice";
import {useDispatch} from "react-redux";
import {produce} from "immer";


ChemicalStructureRecognition.propTypes = {

};

function ChemicalStructureRecognition(props) {
    const dispatch = useDispatch()
    const [blobImg, setBlobImg] = useState(null)
    const [imgSrc, setImgSrc] = useState(null)
    const [molInfo, setMolInfo] = useState({})
    const [molText, setMolText] = useState("")
    const [smiles, setSmiles] = useState("")
    const [loading, setLoading] = useState(false)

    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    const onPaste = async (event) => {
        try {
            const clipboardItem = event.clipboardData
            const itemType = clipboardItem.types.find(type => type.startsWith('Files'))
            if(!itemType){return}
            const blob = clipboardItem.files[0];
            setBlobImg(blob)
            setImgSrc(await blobToBase64(blob))
        } catch (err) {
            console.error(err.name, err.message);
        }
    }

    const onCSRBtn = () => {
        setLoading(true)
        ChemicalStructureRecognitionAPI.csr({file: blobImg}).then(data=>{
            setMolText(data?.mol)
            setSmiles(data?.smiles)
        }).finally(()=>{
            setLoading(false)
        })
    }

    const onCopySmilesBtn = ()=>{
        if(!smiles){return}
        copy(smiles)
        dispatch(popErrorMsg("已复制Smiles"))
    }

    const onCopyMoltextBtn = () => {
        if(!molText){return}
        copy(molText)
        dispatch(popErrorMsg("已复制Moltext"))
    }

    const onMoltextUpdate = (value, ketcher)=>{
        setMolText(value)
        setMolInfo(produce((draft)=>{
            for (let k of Object.keys(draft)){draft[k]='loading'}
        }))
        if(!ketcher){return}
        if(!!value){
            ketcher.indigo.calculate(value)?.then(d=>setMolInfo(d))
        }
        ketcher.getSmiles()?.then(smi=>{
            setSmiles(smi)
        })

    }

    const getValue = (d, key) =>{
        return d.hasOwnProperty(key)?d[key]:"n/a"
    }

    return (
        <Grid container onPaste={onPaste}>
            <Grid item md={4} onPaste={onPaste}>
                <Stack >
                    <div style={{height: '200px', textAlign: 'center'}}>
                    {imgSrc?(
                        <Image src={imgSrc}/>
                    ):(
                        <div>
                            点击此处，再Ctrl+V
                        </div>
                    )}
                    </div>
                    <Stack direction={"row"}>
                        <Button onClick={onCSRBtn} disabled={loading || !blobImg}>识别</Button>
                        <Button onClick={onCopySmilesBtn} disabled={!smiles} >复制Smiles</Button>
                        <Button onClick={onCopyMoltextBtn} disabled={!molText} >复制Moltext</Button>
                    </Stack>
                    <Typography><b>分子式:</b> {getValue(molInfo, 'gross')}</Typography>
                    <Typography><b>分子量:</b> {getValue(molInfo, 'molecular-weight')}</Typography>
                </Stack>
            </Grid>
            <Grid item md={8}>
                <div style={{height: '400px'}}>
                    <MoleculeEditor value={molText} onChange={onMoltextUpdate}/>
                </div>
            </Grid>
        </Grid>
    );
}

export default ChemicalStructureRecognition;