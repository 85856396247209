import Handsontable from "handsontable";

export const NestedJsonRenderer = (field) => (instance, td, row, col, prop, value, cellProperties)=>{
    let v = instance.getSourceDataAtRow(row)
    for (let f of field.split('.')) {
        if(!v || !v?.hasOwnProperty(f)){
            v = null
            break
        }
        v = v[f]
    }
    Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, v, cellProperties])
    td.setAttribute('title', v)
}