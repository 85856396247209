import make_request from "./utils";


class ChemicalStructureRecognitionAPI{

    static async csr({file}) {
        const form = new FormData()
        form.append('image', file)
        return make_request(
            `/api/mod_chemical/chemical/alpha_extract_img2smiles/`, null, {
                body: form,
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                },
            })
    }

}

export default  ChemicalStructureRecognitionAPI;
