import {matchPath} from "react-router-dom"
import {Routes} from "./redux/tab/types";
import ProductList from "./pages/Product/table/ProductList";
import ProductBaseInfo from "./pages/Product/dialog/base/ProductBaseInfo";
import ProductCatalogTable from "./pages/ProductCatalog/table/ProductCatalogTable";
import CustomerInquiryTable from "./pages/CustomerInquiry/table/CustomerInquiryTable";
import CustomerInquiryOrder from "./pages/CustomerInquiry/dialog/CustomerInquiryOrder";
import InternalQuotationTable from "./pages/InternalQuotation/table/InternalQuotationTable";
import SupplierTable from "./pages/Supplier/table/SupplierTable";
import SupplierQuotationTable from "./pages/SupplierQuotation/table/SupplierQuotationTable";
import ChemicalXlsxTaskTable from "./pages/ChemicalXlsxUpload/table/ChemicalXlsxTaskTable";
import SalesBillTable from "./pages/u8/sales_bill/SalesBillTable";
import MenuItemManagePage from "./pages/sys_settings/MenuItemManage/MenuItemManagePage";
import SalesOrderTable from "./pages/u8/sales_order/SalesOrderTable";
import PurchaseBillTable from "./pages/u8/purchase_bill/PurchaseBillTable";
import InquiryOrderSupplierSelection
    from "./pages/InternalQuotation/dialog/InquiryOrderSupplierSelection/InquiryOrderSupplierSelection";
import DingtalkUserTable from "./pages/dingtalk/DingtalkUser/DingtalkUserTable";
import InternalQuotationDialog from "./pages/InternalQuotation/demoDialog/InternalQuotationDialog";
import ExpressTable from "./pages/Express/table/ExpressTable";
import ChemicalStructureRecognition from "./pages/Chemical/detail/ChemicalStructureRecognition";


const routes:Routes = [
    // 系统模块
    {path: '/sys/menu_manage/', title: '菜单权限管理', component: MenuItemManagePage},

    // 普通模块
    {path: '/product/:id', title: '产品信息', component: ProductBaseInfo},
    {path: '/product', title: '产品列表', component: ProductList},
    {path: '/product_form', title: '产品信息', component: ProductBaseInfo},
    {path: '/product_catalog', title: '产品分类列表', component: ProductCatalogTable},

    {path: '/customer_inquiry', title: '客户询报价列表', component: CustomerInquiryTable},
    {path: '/inquiry_order/:id', title: '客户询报价', component: CustomerInquiryOrder},
    {path: '/inquiry_order', title: '客户询报价', component: CustomerInquiryOrder},

    {path: '/inq_supp_selection', title: '供应询价匹配', component: InquiryOrderSupplierSelection},
    // {path: '/inq_supp_selection/:id', title: '供应询价匹配', component: InquiryOrderSupplierSelection},
    {path: '/inter_quotation', title: '内部报价列表', component: InternalQuotationTable},
    {path: '/inter_quotation/:id', title: '产品定价明细', component: InternalQuotationDialog},

    {path: '/supplier', title: '供应商列表', component: SupplierTable},
    {path: '/supplier_quotation', title: '供应商报价列表', component: SupplierQuotationTable},

    {path: '/chemical/extract_xlsx_chem/', title: '提取Excel结构式', component: ChemicalXlsxTaskTable},
    {path: '/chemical/csr/', title: '结构式识别', component: ChemicalStructureRecognition},
    // U8 相关页面
    {path: '/u8/sales_order/', title: '销售订单列表', component: SalesOrderTable},
    {path: '/u8/sales_bill/', title: '销售发票列表', component: SalesBillTable},

    {path: '/u8/purchase_bill/', title: '采购发票列表', component: PurchaseBillTable},

    // 钉钉相关
    {path: '/dingtalk/user/', title: '钉钉用户列表', component: DingtalkUserTable},

    // 快递相关
    {path: '/express/express/', title: '快递列表', component: ExpressTable},

]


export function getRouteByUrl(url: string){
    for (const route of routes) {
        const m = matchPath(route.path, url)
        if(!!m){
            return [m, route]
        }
    }
    return [undefined, undefined]
}

export default routes
